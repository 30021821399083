import React from 'react';


const FAQSection = () => {
  const faqs = [
    {
      question: "Who can attend?",
      answer: "Everyone!"
    },
    {
      question: "How much does it cost?",
      answer: "It's free for Berkeley student! For all other attendees, the registration fee is $50."
    },
    {
      question: "Do I have to stay the whole time?",
      answer: "Feel free to come in and out as your schedule allows. However, we recommend coming by 4:30 PM to check in."
    },
    {
      question: "What do I do if I have more questions?",
      answer: (
        <>
          Send us as email at{' '}
          <a 
            href="mailto:innovate@aez.berkeley.edu" 
            className="text-blue-400 hover:text-blue-300"
          >
            innovate@aez.berkeley.edu
          </a>
          .
        </>
      )
    }
  ];

  return (
    <section className="py-20 bg-slate-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold mb-12">Frequently Asked Questions</h2>
        <div className="space-y-6 max-w-3xl">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="p-6 bg-slate-700/50 rounded-lg hover:bg-slate-700/60 transition-colors"
            >
              <h3 className="text-xl font-semibold text-blue-400 mb-2">
                {faq.question}
              </h3>
              <p className="text-slate-200 text-lg">
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
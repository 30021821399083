import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import innovateLogo from './images/innovate_logo_white_bg.png';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const navLinks = [
    { name: 'About', path: '/about' },
    { name: 'Conference', path: '/conference', isHome: true },
    { name: 'Challenge', path: '/challenge' },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled
          ? 'bg-slate-900/90 backdrop-blur-md shadow-lg'
          : 'bg-black/20 backdrop-blur-sm border-b border-white/10'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Logo */}
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img
                  src={innovateLogo}
                  alt="Innovate @ Berkeley"
                  className="h-12 md:h-16"
                />
              </Link>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="flex items-center space-x-12">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className={`transition-colors uppercase font-medium relative group ${
                    location.pathname === link.path ||
                    (link.isHome && location.pathname === '/')
                      ? 'text-blue-400'
                      : 'text-white hover:text-blue-300'
                  }`}
                >
                  {link.name}
                  <div className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-400 transform scale-x-0 origin-left transition-transform group-hover:scale-x-100" />
                </Link>
              ))}
              <a
                href="https://lu.ma/unzyfzmn"
                target="_blank"
                rel="noopener noreferrer"
                className="px-8 py-2 border-2 border-blue-400 text-white rounded-full hover:bg-blue-400 hover:border-transparent transition-all duration-300 uppercase font-medium transform hover:scale-105"
              >
                Register
              </a>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 text-white hover:text-blue-300 transition-colors"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isMenuOpen ? '' : 'hidden'}`}>
        <div
          className={`${
            isScrolled
              ? 'bg-slate-900/95 backdrop-blur-md'
              : 'bg-black/90 backdrop-blur-sm'
          } p-6`}
        >
          <div className="flex flex-col space-y-6">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                className={`transition-colors uppercase font-medium ${
                  location.pathname === link.path ||
                  (link.isHome && location.pathname === '/')
                    ? 'text-blue-400'
                    : 'text-white hover:text-blue-300'
                }`}
              >
                {link.name}
              </Link>
            ))}
            <a
              href="https://lu.ma/unzyfzmn"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 border-2 border-blue-400 text-white rounded-full hover:bg-blue-400 hover:border-transparent transition-all duration-300 uppercase font-medium text-center transform hover:scale-105"
            >
              Register
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
// Add your image imports here:
import HeroImage from './images/about_header.jpeg';
import RulesImage from './images/challenge_bottom.jpeg';
import Footer from './Footer';
import Navigation from './Navigation';
import carousel1 from './images/carousel1.jpeg';
import carousel2 from './images/carousel2.jpeg';
import carousel3 from './images/carousel3.jpeg';

import aez from './images/aez.png';





const About = () => {

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const carouselItems = [
    {
      image: carousel1,
      title: "Startup Summit 2023",
    },
    {
      image: carousel2,
      title: "Workshop Series",
    },
    {
      image: carousel3,
      title: "VC Panel",
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + carouselItems.length) % carouselItems.length);
  };

  return (
    <div className="min-h-screen bg-gray-50">
        <Navigation />
        <div className="relative h-[80vh] flex flex-col justify-center"> {/* Changed from min-h-screen to h-[80vh] */}
        {/* Background image with updated overlay */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-slate-900/60 z-10" />
          <img 
            src={HeroImage}
            alt="Background"
            className="w-full h-full object-cover opacity-40"
          />
        </div>

        {/* Content */}
        <div className="relative z-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full">
          <h2 className="text-2xl md:text-3xl mb-6 text-white uppercase tracking-wider"> {/* Reduced margin bottom */}
            Our Mission
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-2xl leading-relaxed"> {/* Reduced margin bottom */}
            Innovate@Berkeley is one of the biggest startup conferences in the Bay Area. We aim to capture the entrepreneurial spirit of Silicon Valley and provide a platform for our community to connect, learn, and get inspired.
          </p>
          
          <div className="mb-8"> {/* Reduced margin bottom */}
            <h3 className="text-xl font-semibold text-white uppercase tracking-wider mb-4"> {/* Reduced margin bottom */}
              Innovate in Three Words
            </h3>
            <div className="space-y-3"> {/* Reduced spacing */}
              {['Connect', 'Learn', 'Inspire'].map((item) => (
                <div key={item} className="flex items-center space-x-4 group">
                  <div className="w-2 h-2 bg-white rounded-full group-hover:scale-110 transition-transform" />
                  <span className="text-lg text-white uppercase tracking-wider">{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Carousel Section */}
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">
            Innovate Through The Years
          </h2>
          <div className="relative">
            <div className="overflow-hidden rounded-xl shadow-lg">
              <div className="relative h-[500px]">
                <img 
                  src={carouselItems[currentSlide].image}
                  alt={carouselItems[currentSlide].title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
                  <div className="absolute bottom-0 left-0 p-8">
                    <h3 className="text-3xl font-bold text-white mb-2">
                      {carouselItems[currentSlide].title}
                    </h3>
                    <p className="text-gray-200">
                      {carouselItems[currentSlide].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button 
              onClick={prevSlide}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full hover:bg-white transition-colors shadow-md"
              aria-label="Previous slide"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button 
              onClick={nextSlide}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full hover:bg-white transition-colors shadow-md"
              aria-label="Next slide"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
            <div className="flex justify-center mt-4 space-x-2">
              {carouselItems.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-3 h-3 rounded-full transition-colors ${
                    currentSlide === index ? 'bg-blue-600' : 'bg-gray-300'
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Organizers Section */}
      <div className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">
              Meet the Organizers
            </h2>
            <div className="grid md:grid-cols-12 gap-8 items-center">
              <div className="md:col-span-5">
                <div className="flex justify-center items-center">
                  <img 
                    src={aez}
                    alt="Alpha Epsilon Zeta Logo" 
                    className="w-full rounded-lg"
                  />
                </div>
              </div>
              <div className="md:col-span-7">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">Alpha Epsilon Zeta</h3>
                <p className="text-lg text-gray-600 mb-6">
                  Innovate@Berkeley is organized by the brothers of Alpha Epsilon Zeta (AEZ). 
                  AEZ distinguishes itself from professional fraternities by embracing a 
                  multidisciplinary focus, dedicated to promoting and fostering professionalism, 
                  brotherhood, unity and tradition.
                </p>
                <div className="bg-gray-50 p-6 rounded-xl">
                  <h4 className="text-lg font-semibold text-gray-900 mb-3">Past Events Include:</h4>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Stock market competitions</li>
                    <li>• Case competitions</li>
                    <li>• Startup workshops</li>
                    <li>• Company talks</li>
                    <li>• Career panels</li>
                  </ul>
                </div>
                <a 
                href="https://www.aezberkeley.org/"
                className="mt-8 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';
import Challenge from './Challenge';
import About from './About';
import InnovateBerkeley from './InnovateBerkeley';

const ExternalRedirect = () => {
  window.location.href = 'https://lu.ma/unzyfzmn';
  return null;
};

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<InnovateBerkeley />} />
        <Route path="/about" element={<About />} />
        <Route path="/conference" element={<InnovateBerkeley />} />
        <Route path="/challenge" element={<Challenge />} />
        <Route path="/register" element={<ExternalRedirect />} />
      </Routes>
    </Router>
  );
};

export default App;
import React from 'react';

const ScheduleItem = ({ time, event, speakers, subheading }) => {
  const renderSpeakerInfo = () => {
    if (!speakers || speakers.length === 0) return null;

    return (
      <div className="mt-8">
        {/* If it's a single speaker */}
        {speakers.length === 1 ? (
          <div className="space-y-4 sm:ml-40">
            <div className="flex items-start gap-4">
              <img 
                src={speakers[0].image} 
                alt={speakers[0].name}
                className="w-20 h-20 rounded-full object-cover"
              />
              <div className="flex-1">
                <h3 className="text-xl font-semibold">{speakers[0].name}</h3>
                <p className="text-blue-400">{speakers[0].title}</p>
              </div>
              {speakers[0].linkedIn && (
                <a 
                  href={speakers[0].linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 hover:bg-slate-600 rounded-full"
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  </svg>
                </a>
              )}
            </div>
            <p className="text-slate-300 leading-relaxed">
              {speakers[0].bio}
            </p>
          </div>
        ) : (
          // If there are multiple speakers
          <div className="space-y-8 sm:ml-40">
            {/* Panel description if provided */}
            {speakers[0].panelDescription && (
              <p className="text-slate-300 leading-relaxed mb-6">
                {speakers[0].panelDescription}
              </p>
            )}
            
            {/* Grid of speakers */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {speakers.map((speaker, index) => (
                <div key={index} className="space-y-4">
                <div className="flex items-start gap-4">
                    <img 
                    src={speaker.image} 
                    alt={speaker.name}
                    className="w-16 h-16 rounded-full object-cover"
                    />
                    <div className="flex-1">
                    <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold">{speaker.name}</h3>
                        {speaker.linkedIn && (
                        <a 
                            href={speaker.linkedIn}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-1 hover:bg-slate-600 rounded-full"
                        >
                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                            </svg>
                        </a>
                        )}
                    </div>
                    <p className="text-blue-400 text-sm">{speaker.title}</p>
                    </div>
                </div>
                <p className="text-slate-300 text-sm leading-relaxed">
                    {speaker.shortBio || speaker.bio}
                </p>
                </div>
            ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-6 bg-slate-700/50 rounded-lg hover:bg-slate-700/60 transition-colors">
      <div className="flex flex-col sm:flex-row items-start gap-4">
        <div className="text-blue-400 font-mono w-40">{time}</div>
        <div className="flex-1">
          <div className="font-medium text-2xl font-bold">{event}</div>
          {subheading && (
            <h4 className="text-blue-400 text-lg font-semibold mt-2">{subheading}</h4>
          )}
        </div>
      </div>
      {renderSpeakerInfo()}
    </div>
  );
};

export default ScheduleItem;
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Shield } from 'lucide-react';
// Add your image imports here:
import HeroImage from './images/challenge_hero.jpeg';
import RulesImage from './images/challenge_bottom.jpeg';
import Footer from './Footer';
import Navigation from './Navigation';


const Timeline = ({ items }) => (
  <div className="bg-slate-800/50 rounded-xl p-6 sm:p-8 backdrop-blur-sm">
    <h3 className="text-lg sm:text-xl font-semibold text-white mb-6 sm:mb-8">Timeline</h3>
    <div className="space-y-6 sm:space-y-8">
      {items.map((item, index) => (
        <div key={index} className="flex items-start gap-3 sm:gap-4 group">
          <div 
            className={`w-2.5 sm:w-3 h-2.5 sm:h-3 rounded-full mt-2 transition-all duration-300
              ${item.active ? 'bg-blue-500 animate-pulse' : 'bg-gray-600'}
              group-hover:scale-110`}
          />
          <div>
            <h4 className="text-white font-medium group-hover:text-blue-400 transition-colors duration-300">
              {item.title}
            </h4>
            <p className="text-sm sm:text-base text-gray-400">{item.date}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const RulesSection = () => {
  const rules = [
    {
      category: "Eligibility",
      items: [
        'The challenge is open to all college students. Participants must register with their school email address',
        'Only startups that have raised less than $1 million are eligible.'
      ]
    },
    {
      category: "Team Requirements",
      items: [
        'Teams can consist of 1 - 5 people.',
        'Every person can only be a part of one team.',
        'Teams that compete must be willing to travel to UC Berkeley on Nov 12 if they proceed to finals.'
      ]
    },
    {
      category: "Presentation & Prizes",
      items: [
        'The pitch deck should be between 10-14 slides.',
        'All people enrolled in the challenge are automatically enrolled for the conference.',
        'Equal shares of the prize money will be paid individually to all members of the team.'
      ]
    }
  ];

  return (
    <div className="bg-slate-800/30 py-12 sm:py-24">
      <div className="container mx-auto px-4">
        <div className="flex items-center gap-3 mb-8 sm:mb-12">
          <Shield className="w-8 h-8 text-blue-500" />
          <h2 className="text-2xl sm:text-3xl font-bold text-white">Challenge Rules</h2>
        </div>
        
        <div className="grid md:grid-cols-3 gap-6">
          {rules.map((category, idx) => (
            <div 
              key={idx} 
              className="bg-slate-800/50 border border-slate-700 rounded-lg hover:bg-slate-800/70 transition-all duration-300"
            >
              <div className="p-6">
                <h3 className="text-lg font-semibold text-blue-400 mb-4">
                  {category.category}
                </h3>
                <div className="space-y-4">
                  {category.items.map((rule, ruleIdx) => (
                    <div key={ruleIdx} className="flex items-start gap-3 group">
                      <div className="w-2 h-2 rounded-full bg-blue-500 mt-2 group-hover:scale-125 transition-transform duration-300" />
                      <p className="text-sm text-gray-300 group-hover:text-white transition-colors duration-300">
                        {rule}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ChallengePage = () => {
  const timeline = [
    {
      title: 'Application Due',
      date: 'November 9, 2024',
      active: true
    },
    {
      title: 'Top 6 Announced',
      date: 'November 10, 2024',
      active: false
    },
    {
      title: 'Live Presentations',
      date: 'November 12, 2024',
      active: false
    }
  ];

  const HeroSection = () => (
    <div className="relative min-h-[90vh] sm:min-h-screen flex flex-col justify-center">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/60 to-slate-900/80" />
        <img 
          src={HeroImage}
          alt="Challenge Background"
          className="w-full h-full object-cover opacity-30 sm:opacity-40"
          loading="eager"
        />
      </div>
      
      <div className="relative z-10 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full text-center sm:text-left">
        <p className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-white uppercase tracking-wider animate-fade-in">
          Challenge Yourself
        </p>
        <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mb-4 sm:mb-6 text-white">
          <span className="block sm:inline">Compete at </span>
          <span className="text-blue-400 inline-block">Innovate</span>
        </h1>
        <p className="text-base sm:text-lg md:text-xl mb-8 sm:mb-12 text-white uppercase tracking-wider">
          Register now for the competition
        </p>
        <a 
          href="https://airtable.com/appYzgXyR6seZkXnJ/pag1l2RvbMr320YeE/form"
          className="group w-full sm:w-auto px-6 sm:px-8 py-4 sm:py-3 border-2 border-white text-white rounded-full 
            hover:bg-white hover:text-slate-900 transition-all transform hover:scale-105 
            active:scale-95 uppercase tracking-wider inline-block text-center
            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
        >
          Register Now
          <ChevronRight className="inline-block ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
        </a>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-slate-900">
      <Navigation />
      
      {/* Hero Section - Enhanced Mobile Optimization */}
      {/* <div className="relative min-h-[90vh] sm:min-h-screen flex flex-col justify-center">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-900/60 to-slate-900/80" />
          <img 
            src={HeroImage}
            alt="Challenge Background"
            className="w-full h-full object-cover opacity-30 sm:opacity-40"
            loading="eager"
          />
        </div>
        
        <div className="relative z-10 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full text-center sm:text-left">
          <p className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-8 text-white uppercase tracking-wider animate-fade-in">
            Challenge Yourself
          </p>
          <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mb-4 sm:mb-6 text-white max-w-4xl flex flex-col sm:flex-row sm:items-center sm:justify-start">
            <span className="mb-2 sm:mb-0 sm:mr-3">Compete at</span>
            <span className="text-blue-400">Innovate</span>
          </h1>
          <p className="text-base sm:text-lg md:text-xl mb-8 sm:mb-12 text-white uppercase tracking-wider">
            Register now for the competition
          </p>
          <a 
            href="https://airtable.com/appYzgXyR6seZkXnJ/pag1l2RvbMr320YeE/form"
            className="group w-full sm:w-auto px-6 sm:px-8 py-4 sm:py-3 border-2 border-white text-white rounded-full 
              hover:bg-white hover:text-slate-900 transition-all transform hover:scale-105 
              active:scale-95 uppercase tracking-wider inline-block text-center
              focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-slate-900"
          >
            Register Now
            <ChevronRight className="inline-block ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </div> */}

      <HeroSection />

      {/* Challenge Info Section - Enhanced Mobile Optimization */}
      <div className="container mx-auto px-4 py-12 sm:py-24">
        <div className="grid lg:grid-cols-2 gap-8 sm:gap-16 items-start">
          <div className="space-y-6 sm:space-y-8">
            <h2 className="text-2xl sm:text-3xl font-bold text-white">
              Innovate@Berkeley Pitch Challenge
            </h2>
            <div className="prose prose-invert max-w-none">
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Grab your friends and <span className="text-blue-400 font-medium">create your own startup</span> for 
                a chance to win up to <span className="text-blue-400 font-medium">$500</span>! Put your heads together, 
                craft a pitch deck, and submit it. It's that easy. Once all of the applications are submitted, 
                our team of judges will select the <span className="text-blue-400 font-medium">top 6 teams</span> and those 
                teams will have the opportunity to present live at the conference. After all 6 presentations, 
                the judges will pick the <span className="text-blue-400 font-medium">top 3 teams</span> to win a cash prize 
                of $1000, $500, and $250.
              </p>
            </div>
            <div className="relative h-48 sm:h-64 rounded-xl overflow-hidden mt-6 sm:mt-8 shadow-xl">
              <img 
                src={RulesImage}
                alt="Challenge Rules"
                className="w-full h-full object-cover"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-slate-900/60" />
            </div>
          </div>

          {/* Timeline Component */}
          <Timeline items={timeline} />
        </div>
      </div>

      {/* Rules Section Component */}
      <RulesSection />
      
      <Footer />
    </div>
  );
};

export default ChallengePage;
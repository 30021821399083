import React, { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import { Menu, X } from 'lucide-react';
import './index.css';
import innovateLogo from './images/innovate_logo.png';
import hero from './images/hero.jpg';
import ScheduleItem from './ScheduleItem';
import shaun from './images/shaun.jpg';
import ann from './images/ann.jpg';
import vedant from './images/vedant.jpg';
import deedy from './images/deedy.jpg';
import jonathan from './images/jonathan.jpg';
import nikhil from './images/nikhil.jpeg';
import marcus from './images/marcus.jpeg';
import connor from './images/connor.jpeg';
import alagu from './images/alagu.jpeg';
import vinay from './images/vinay.jpeg';
import akash from './images/akash.png';
import grace from './images/grace.jpeg';


import shelly_judge from './images/shelly_judge.jpeg';
import stefen_judge from './images/stefen_judge.jpeg';
import torri_judge from './images/torri_judge.jpeg';
import alex_judge from './images/alex_judge.jpeg';
import amber_judge from './images/amber_judge.jpeg';



import current_startups from './images/current_startups.png';
import past_startups from './images/prev_startups.png';
import sponosors from './images/innovate_sponsors.jpg';
import FAQSection from './FAQSection';
import Footer from './Footer';
import Navigation from './Navigation';






const scheduleItems = [
  { 
    time: "4:30 - 5:00", 
    event: "Registration and Check in" 
  },
  { 
    time: "5:00 - 5:45", 
    event: "Welcome and Networking" 
  },
  { 
    time: "5:45 - 6:15", 
    event: "Keynote Speaker",
    speakers: [{
      name: "Shaun Maguire",
      title: "Partner, Sequoia Capital",
      image: shaun, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/shaun-maguire/",
      bio: "Shaun Maguire, a Partner at Sequoia Capital, draws on his scientific background and entrepreneurial experience to invest in groundbreaking startups. His portfolio features prominent investments in companies like AMP Robotics, Knowde, Vise, and Watershed, emphasizing sectors where advanced technology meets business innovation. Additionally, Shaun has played a key role in supporting SpaceX, focusing on transformative solutions that drive the future of industries. With a keen eye for disruptive potential, he is dedicated to fostering ventures that challenge the status quo and shape the technological landscape."
    }]
  },
  { 
    time: "6:15 - 7:15", 
    event: "Startup Pitch Challenge" 
  },
  { 
    time: "7:15 - 8:00", 
    event: "Venture Capitalist Panel",
    subheading: "Speakers",
    speakers: [
      {
        name: "Ann Bordetsky",
        title: "Partner, New Enterprise Associates",
        image: ann, // Replace with actual image path
        linkedIn: "https://www.linkedin.com/in/annbordetsky/",
        bio: "Ann Bordetsky is a Partner at NEA, where she invests in early-stage consumer technology and AI application companies. She focuses on supporting founders who are reimagining how we work, live, and connect, with a commitment to diversity and high-performing teams. Her portfolio includes innovative startups such as, which empowers independent workers;, which uses AI to enhance recycling processes;, a leader in intelligent video search technology; and, which aims to revolutionize traditional search with advanced answer engines. Ann is dedicated to fostering impactful change in the startup ecosystem and actively supports women in leadership roles across the industry."
      },
      {
      name: "Deedy Das",
      title: "Principal, Menlo Ventures",
      image: deedy, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/debarghyadas/",
      bio: "Deedy Das is a Principal at Menlo Ventures, where he focuses on early-stage investments in AI, SaaS, and infrastructure through Menlo's $1.4B fund, alongside the Anthology Fund, a $100M venture with Anthropic. His recent investments include notable companies like Unstructured, Pinecone, and Anthropic. Previously, Deedy was on the founding team at Glean, where he co-created Glean Assistant—a generative AI product driving significant revenue for the company. His engineering and leadership expertise also extends to Google and Facebook, where he developed products in Search Intelligence and Infrastructure. Outside of work, Deedy is a marathoner, cyclist, and a widely-read writer with a blog reaching over 100 million views."
    },
    {
      name: "Jonathan Mendelson",
      title: "Investor, Accel",
      image: jonathan, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/mendelsonjonathan/",
      bio: "Jonathan Mendelson is an Investor at Accel, where he focuses on late-stage investments in enterprise software and consumer businesses. With prior experience in investment banking at Morgan Stanley in their Global Technology Group, he brings a strong analytical background to his role. A graduate of UC Berkeley with degrees in Business Administration and Public Policy, Jonathan leverages his education to identify and nurture innovative companies. Based in the Bay Area, he is passionate about technology's transformative power and aims to support ventures that push the boundaries of what's possible in the industry."
    },
    {
      name: "Vedant Suri",
      title: "Investor, General Catalyst",
      image: vedant, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/vedantsuri/",
      bio: "Vedant began his investment career at, Alphabet's growth investment fund, focusing on B2B SaaS and data infrastructure. He later joined, where he invests in enterprise software companies, emphasizing those led by founders with a deep understanding of their challenges. His portfolio includes, an industrial operations platform that enhances maintenance through predictive analytics, helping major companies like John Deere and Bosch optimize their operations. Vedant is passionate about supporting innovative teams that drive efficiency and technological advancements in their industries."
    }]
  },
  { 
    time: "7:45 - 8:00", 
    event: "Pitch Challenge Awards",
    subheading: "Judges",
    speakers: [
      {
        name: "Stefan Sandoval Yepes",
        title: "Investor, JetBlue Ventures",
        image: stefen_judge, // Replace with actual image path
        linkedIn: "https://www.linkedin.com/in/stefansandovalyepes/",
      },
      {
      name: "Tori Orr",
      title: "Investor, Jet Blue Ventures",
      image: torri_judge, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/toriorr/",
    },
    {
      name: "Amber Yang",
      title: "Investor, Bloomberg Beta",
      image: amber_judge, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/amber-yang/",
    },
    {
      name: "Shelly Rao",
      title: "Director, Juniper Ventures",
      image: shelly_judge, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/shellyrao/",
    },
    {
      name: "Alex Tran",
      title: "Investor, CapitalG",
      image: alex_judge, // Replace with actual image path
      linkedIn: "https://www.linkedin.com/in/alexduongtran/"    
    }]
  }
];

const InnovateBerkeley = () => {
    const typedRef = useRef(null);
    const typedInstance = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 20) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
      if (typedRef.current) {
        typedInstance.current = new Typed(typedRef.current, {
          strings: [" build.", " create.", " innovate."],
          typeSpeed: 50,
          backSpeed: 50,
          backDelay: 2000,
          startDelay: 1000,
          loop: true,
          showCursor: true,
          cursorChar: '|'
        });
    
        return () => {
          if (typedInstance.current) {
            typedInstance.current.destroy();
          }
        };
      }
    }, []);
  
    return (
      <div className="min-h-screen bg-slate-900 text-white">
        {/* Navigation */}
        <Navigation />
  
       {/* Hero Section */}
      <div className="relative min-h-screen flex flex-col justify-start pt-[25vh]">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-slate-900/60" />
          <img 
            src={hero}
            alt="Background"
            className="w-full h-full object-cover opacity-40"
          />
        </div>

        <div className="relative z-10 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto w-full">
          <p className="text-xl md:text-3xl mb-8 md:mb-12 text-white uppercase tracking-wider">
            Berkeley&apos;s Largest Entrepreneurship Expo
          </p>
          <h1 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-bold mb-12 md:mb-8 text-white md:whitespace-nowrap">
            Together we can <span ref={typedRef}></span>
          </h1>
          <p className="text-base md:text-xl mb-10 md:mb-10 text-white uppercase tracking-wider">
            Startup Disruption in Berkeley&apos;s frontyard, Powered by AEZ
          </p>
          <a 
            href="https://lu.ma/unzyfzmn"
            className="inline-block px-6 md:px-8 py-3 border-2 border-white text-white rounded-full hover:bg-white hover:text-slate-900 transition-all transform hover:scale-105 uppercase tracking-wider text-sm md:text-base">
            Register Now
          </a>
        </div>

      <div className="mt-20 left-0 right-0 md:right-auto bg-white/10 backdrop-blur-sm p-4 md:p-8 mx-4 md:mx-0 md:max-w-sm">
          <div className="text-white/80 uppercase tracking-wider text-xs md:text-sm">
            <p>Berkeley Haas</p>
            <p>Spieker Forum</p>
            <p>Berkeley, CA</p>
            <p className="mt-2 md:mt-4 text-base md:text-lg font-medium">November 12, 2024</p>
          </div>
        </div>
      </div>

          
          {/* Scroll Indicator */}
          <div 
            onClick={() => {
              document.getElementById('schedule').scrollIntoView({ behavior: 'smooth' });
            }}
            className="absolute bottom-8 right-4 md:right-8 border-2 border-white rounded-full p-3 md:p-4 cursor-pointer hover:bg-white hover:text-slate-900 transition-all"
          >
            <svg 
              className="w-4 h-4 md:w-6 md:h-6 text-white" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M19 14l-7 7m0 0l-7-7m7 7V3" 
              />
            </svg>
          </div>
        
        
      
          {/* Schedule Section */}
          <section className="py-12 md:py-20 bg-slate-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" id="schedule">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12">Schedule</h2>
              <div className="space-y-6 md:space-y-8">
                {scheduleItems.map((item, index) => (
                  <ScheduleItem
                    key={index}
                    time={item.time}
                    event={item.event}
                    speakers={item.speakers}
                    subheading={item.subheading}
                  />
                ))}
              </div>
            </div>
          </section>

         {/* Current Year's Startups */}
      {/* Current Year's Startups */}
      <section className="py-8 bg-slate-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-700 mb-8 md:mb-12">This Year's Featured Startups</h2>
            <div className="flex justify-center">
              <img 
                src={current_startups} 
                alt="This year's featured startups" 
                className="w-full max-w-[600px] h-auto object-contain"
              />
            </div>
          </div>
        </section>

        {/* Past Featured Startups */}
        <section className="py-8 bg-slate-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-700 mb-8 md:mb-12">Past Featured Startups</h2>
            <div className="flex justify-center">
              <img 
                src={past_startups} 
                alt="Past featured startups" 
                className="w-full max-w-[600px] h-auto object-contain bg-white p-4 md:p-8 rounded-lg"
              />
            </div>
          </div>
        </section>

        {/* Sponsors */}
        <section className="py-8 bg-slate-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl md:text-3xl font-bold text-blue-700 mb-8 md:mb-12">Sponsors</h2>
            <div className="flex justify-center">
              <img 
                src={sponosors} 
                alt="Sponsors" 
                className="w-full max-w-[600px] h-auto object-contain bg-white p-4 md:p-8 rounded-lg"
              />
            </div>
          </div>
        </section>


      {/* Past Speakers */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-700 mb-12">Past Speakers and Panelists</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                name: "Nikhil Arora",
                title: "Co-Founder",
                company: "Back to the Roots",
                image: nikhil
              },
              {
                name: "Alagu Periyannan",
                title: "Co-Founder",
                company: "BlueJeans",
                image: alagu
              },
              {
                name: "Marcus Ryu",
                title: "Partner",
                company: "Battery Ventures",
                image: marcus
              },
              {
                name: "Vinay Iyengar",
                title: "Principal",
                company: "Two Sigma Ventures",
                image: vinay
              },
              {
                name: "Grace Ma",
                title: "Investor",
                company: "Bessemer Venture Partners",
                image: grace
              },
              {
                name: "Connor Love",
                title: "Partner",
                company: "Lightspeed Venture Partners",
                image: connor
              },
              {
                name: "Akash Malhotra",
                title: "Partner",
                company: "The House Fund",
                image: akash
              }
            ].map((speaker, index) => (
              <div className="bg-slate-700 rounded-lg p-6 hover:bg-slate-600 transition-colors">
              <div className="flex items-center gap-4">
                <img
                  src={speaker.image}
                  alt={speaker.name}
                  className="w-16 h-16 rounded-full object-cover border-2 border-slate-500"
                />
                <div>
                  <h3 className="font-semibold text-lg text-white mb-1">{speaker.name}</h3>
                  <p className="text-slate-200 text-sm">{speaker.company}</p>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </section>
      <FAQSection />


        {/* Logistics Section */}
         {/* Logistics Section */}
        <section className="py-12 md:py-20 bg-slate-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12">Logistics</h2>
            <div className="grid md:grid-cols-2 gap-4 md:gap-8">
              <div className="p-6 md:p-8 bg-slate-800 rounded-lg">
                <h3 className="text-lg md:text-xl font-semibold mb-4">Date & Time</h3>
                <p className="text-slate-300">November 12, 2024</p>
                <p className="text-slate-300">5 - 8 PM</p>
              </div>
              <div className="p-6 md:p-8 bg-slate-800 rounded-lg">
                <h3 className="text-lg md:text-xl font-semibold mb-4">Location</h3>
                <p className="text-slate-300">Chou Hall Floor 6</p>
                <p className="text-slate-300">Cheit Ln, Berkeley, CA 94720</p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
};
  
export default InnovateBerkeley;
import React from 'react';
import { Mail, Facebook, Instagram } from 'lucide-react';

import innovateLogo from './images/innovate_logo_white_bg.png';

const Footer = () => {
  const socialLinks = [
    { name: 'innovate@aez.berkeley.edu', icon: Mail, href: 'mailto:innovate@aez.berkeley.edu' },
    { name: '@berkeleyinnovate', icon: Facebook, href: 'https://facebook.com/berkeleyinnovate' },
    { name: '@berkeleyinnovate', icon: Instagram, href: 'https://instagram.com/berkeleyinnovate' },
  ];

  return (
    <>
      {/* Gradient Transition Section */}
      <div className="h-32 bg-gradient-to-b from-[#1a1f2e] to-[#2845c6]" />
      
      <footer className="bg-[#2845c6] text-white">
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Brand Section */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                {/* Logo */}
                <img 
                  src={innovateLogo} 
                  alt="Innovate Berkeley Logo" 
                  className="h-8 w-auto"
                />
              </div>
              <div className="text-blue-100 text-sm">
                <h3 className="font-semibold mb-2">ABOUT</h3>
                <p>
                  Innovate@Berkeley is the largest startup expo at Berkeley and is the ultimate hub for 
                  entrepreneurship. Speakers, startup booths, and challenges make for an exciting program 
                  of events that involves the entire startup community.
                </p>
              </div>
            </div>

            {/* Connect Section */}
            <div className="md:ml-auto">
              <h3 className="text-lg font-semibold mb-4">FOLLOW US</h3>
              <div className="space-y-4">
                {socialLinks.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center space-x-2 text-blue-100 hover:text-white transition-colors duration-200"
                  >
                    <item.icon className="w-5 h-5" />
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="mt-12 pt-8 border-t border-opacity-20 border-white">
            <div className="flex justify-center md:justify-end">
              <p className="text-sm text-blue-100">
                © 2024 Innovate at Berkeley. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;